import { BButton, BCard } from '@/components/bootstrap'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal'
import Link from '@/components/Link'
import StreamModal from '@/components/streams/StreamModal'
import WayupTable from '@/components/WayupTable'
import { CellData, Column, Sort } from '@/components/WayupTable/types'
import { streamsStore } from '@/store'
import { IStream } from '@/store/streamsStore'
import { Vue, Component, Ref } from 'vue-property-decorator'

@Component
export default class StreamsPage extends Vue {
  @Ref() readonly deleteConfirmationModal!: DeleteConfirmationModal
  @Ref() readonly streamModal!: StreamModal

  columns: Column<IStream>[] = [
    { field: 'id', title: 'id', sortable: true },
    { field: 'title', title: 'Название', sortable: true },
    { field: 'date_start', title: 'Дата старта', type: 'date', sortable: true },
    { field: 'lessons_count', title: 'Количество уроков', sortable: true },
    { field: 'students_count', title: 'Количество студентов', sortable: true },
    { customField: 'buttons', title: 'Действия', centered: true, noWrap: true },
  ]

  initialSort: Sort<IStream> = {
    field: 'id',
    dir: 'desc',
  }

  get streams() {
    return streamsStore.streams
  }

  async deleteStream(stream: IStream) {
    const result = await this.deleteConfirmationModal.show(stream.title)

    if (result) {
      await streamsStore.deleteStream(stream.id)
    }
  }

  async duplicateStream(stream: IStream) {
    const result = await this.$bvModal.msgBoxConfirm(
      `Вы действительно хотите копировать поток "${stream.title}"?`,
      {
        title: 'Подтверждение копирования',
        centered: true,
        okTitle: 'Копировать',
        cancelTitle: 'Отмена',
      },
    )

    if (result) {
      await streamsStore.duplicateStream(stream.id)
    }
  }

  renderSlot({ column, row, value }: CellData<IStream>) {
    if (column.customField === 'buttons') {
      return (
        <div>
          <BButton
            onClick={() => this.duplicateStream(row)}
            variant="outline-dark"
            class="mr-50 p-50"
          >
            <feather-icon icon="CopyIcon" size="16" />
          </BButton>
          <BButton
            onClick={() => this.streamModal.show(row)}
            variant="outline-primary"
            class="mr-50 p-50"
          >
            <feather-icon icon="EditIcon" size="16" />
          </BButton>
          <BButton
            onClick={() => this.deleteStream(row)}
            variant="outline-danger"
            class="p-50"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </BButton>
        </div>
      )
    }

    switch (column.field) {
      case 'title':
      case 'number':
        return <Link to={`/streams/${row.id}`}>{value}</Link>
    }
  }

  protected render() {
    return (
      <BCard no-body>
        <div class="p-1">
          <BButton variant="primary" onClick={() => this.streamModal.show()}>
            Добавить поток
          </BButton>
        </div>
        <WayupTable
          rows={this.streams}
          columns={this.columns}
          totalRows={this.streams.length}
          perPage={30}
          sort={this.initialSort}
          perPageOptions={[10, 30, 50, 100]}
          paginationEnabled
          local
          scopedSlots={{ default: cellData => this.renderSlot(cellData) }}
        />
        <DeleteConfirmationModal ref="deleteConfirmationModal" />
        <StreamModal ref="streamModal" />
      </BCard>
    )
  }
}
