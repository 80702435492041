import { IPromocode } from '@/store/promocodesStore'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import Promocodes from '.'
import { BModal } from '@/components/bootstrap'

@Component
export default class PromocodeSelectModal extends tsx.Component<{
  show?: () => void
  onSelect?: (promocode: IPromocode) => void
}> {
  modalId = 'promocode-select-modal'

  async show() {
    this.$bvModal.show(this.modalId)
  }

  protected render() {
    return (
      <BModal
        id={this.modalId}
        centered
        title="Выбор промокода"
        size="xl"
        hide-footer
      >
        <Promocodes
          isModal
          onSelect={promocode => {
            this.$emit('select', promocode)
            this.$bvModal.hide(this.modalId)
          }}
        />
      </BModal>
    )
  }
}
