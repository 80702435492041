import { Component, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import {
  BModal,
  BInput,
  BFormGroup,
  BAvatar,
  BOverlay,
  BButton,
} from '@/components/bootstrap'
import {
  FieldValidator,
  greater,
  notEmpty,
  ValidationGroup,
} from '@/helpers/validation'
import DateTimePicker from '@/components/controls/DateTimePicker'
import { streamsStore } from '@/store'
import { BvEvent } from 'bootstrap-vue'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { IStream } from '@/store/streamsStore'
import { showError } from '@/helpers/notifications'
import PromocodeSelectModal from '../promocodes/PromocodeSelectModal'

@Component
export default class StreamModal extends tsx.Component<{
  show?: (stream?: IStream) => Promise<void>
}> {
  @Ref() readonly promocodeSelectModal!: PromocodeSelectModal

  modalId = 'stream-modal'
  state: 'new' | 'edit' = 'new'
  streamId = -1
  pending = false

  tgMask = {
    mask: 'https://t.me/XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    tokens: { X: { pattern: /[0-9a-zA-Z+-_!]/ } },
  }

  validator = new ValidationGroup()

  title = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.validator,
  })

  startDate = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.validator,
  })

  // useCustomStreamNumber = false
  // customStreamNumber = new FieldValidator<number | null>({
  //   value: null,
  //   ignoreValidation: this.checkCustomNumberValidation,
  //   validators: [notEmpty(), greater(0)],
  //   group: this.validator,
  // })
  // checkCustomNumberValidation() {
  //   return !this.useCustomStreamNumber
  // }

  promocodeId = new FieldValidator<number | null>({
    value: null,
    ignoreValidation: this.checkPromocodeValidation,
    validators: [notEmpty(), greater(0)],
    group: this.validator,
  })
  checkPromocodeValidation() {
    return this.courseType === 'paid'
  }

  telegramChat = new FieldValidator({
    value: '',
    validators: [],
    group: this.validator,
  })

  telegramBot = new FieldValidator({
    value: '',
    validators: [],
    group: this.validator,
  })

  telegramNotifications = new FieldValidator({
    value: '',
    validators: [],
    group: this.validator,
  })

  mentors: {
    id: number
    name: string
    avatar?: string
    telegram: string
    telegramId: number
  }[] = []

  get modalTitle() {
    if (this.state === 'new') {
      return 'Добавить поток'
    }
    return 'Редактировать поток'
  }

  get okButtonTitle() {
    if (this.state === 'new') {
      return 'Создать'
    }
    return 'Сохранить'
  }

  get courseType() {
    return streamsStore.courseType
  }

  // @Watch('useCustomStreamNumber')
  // onUseCustomStreamNumberChange(value: boolean) {
  //   if (!value) {
  //     this.customStreamNumber.set(null)
  //     this.customStreamNumber.reset()
  //   }
  // }

  async show(stream?: IStream) {
    this.state = stream ? 'edit' : 'new'
    this.$bvModal.show(this.modalId)

    this.pending = true

    await streamsStore.checkCourseType(this.$route.params.id)

    if (this.courseType === 'paid') {
      try {
        const response: AxiosResponse<{
          mentors: {
            id: number
            name: string
            avatar?: string
          }[]
        }> = await axios.get(`v1/courses/${this.$route.params.id}/mentors`)

        const mentors = response.data.mentors as any

        for (const mentor of mentors) {
          mentor.telegram = ''
          mentor.telegramId = null
        }

        this.mentors = mentors
      } catch (error) {
        showError('Произошла ошибка при загрузке менторов')
        console.error(error)
      }
    }

    if (stream) {
      this.fillData(stream)
    }

    this.pending = false
  }

  fillData(stream: IStream) {
    this.streamId = stream.id

    this.title.set(stream.title)
    this.startDate.set(stream.date_start)
    this.promocodeId.set(stream.promocode)
    this.telegramBot.set(stream.telegram_bot || '')
    this.telegramChat.set(stream.telegram_chat || '')
    this.telegramNotifications.set(stream.telegram_notifications || '')
    for (const chat of stream.telegram_chats) {
      const mentor = this.mentors.find(m => m.id === chat.mentor_id)
      if (mentor) {
        mentor.telegramId = chat.id
        mentor.telegram = chat.telegram_url
      }
    }
  }

  async submit(e: BvEvent) {
    e.preventDefault()

    if (!this.validator.validate()) {
      return
    }

    this.pending = true
    let result = false

    const data = {
      course_id: this.$route.params.id,
      title: this.title.value!,
      date_start: this.startDate.value!,
      // number: this.useCustomStreamNumber
      //   ? this.customStreamNumber.value!
      //   : (false as false), // хз почему не null, вопрос к бэку
      number: false as false,
      promocode: this.promocodeId.value!,
      promocode_id: this.promocodeId.value!,
      telegram_bot: this.telegramBot.value,
      telegram_chat: this.telegramChat.value,
      telegram_notifications: this.telegramNotifications.value,
    }

    if (this.state === 'new') {
      result = await streamsStore.addStream({
        data,
        mentors: this.mentors,
      })
    } else {
      result = await streamsStore.saveStream({
        data,
        mentors: this.mentors,
        streamId: this.streamId,
      })
    }

    this.pending = false

    if (result) {
      this.$bvModal.hide(this.modalId)
    }
  }

  onHidden() {
    this.title.set('')
    this.startDate.set('')
    // this.useCustomStreamNumber = false
    // this.customStreamNumber.set(null)
    this.promocodeId.set(null)
    this.telegramBot.set('')
    this.telegramChat.set('')
    this.telegramNotifications.set('')
    this.mentors = []
    this.pending = false

    this.$nextTick(() => this.validator.resetErrors())
  }

  protected render() {
    return (
      <BModal
        id={this.modalId}
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        ok-title={this.okButtonTitle}
        cancel-title="Отмена"
        centered
        title={this.modalTitle}
        onHidden={this.onHidden}
        onOk={this.submit}
        busy={this.pending}
      >
        <BOverlay show={this.pending} no-wrap />

        <BFormGroup
          label="Название"
          state={this.title.hasError ? false : null}
          invalid-feedback={this.title.errorText}
        >
          <BInput
            value={this.title.value}
            onInput={this.title.set}
            placeholder="Введите название"
            state={this.title.hasError ? false : null}
          />
        </BFormGroup>

        <BFormGroup
          label="Дата старта"
          state={this.startDate.hasError ? false : null}
          invalid-feedback={this.startDate.errorText}
        >
          <DateTimePicker
            value={this.startDate.value}
            onInput={this.startDate.set}
            displayedFormat="l d M Y"
          />
        </BFormGroup>

        {/* {this.state === 'new' && (
          <BCheckbox
            checked={this.useCustomStreamNumber}
            onInput={value => (this.useCustomStreamNumber = value)}
            class="mb-1"
          >
            Начать нумерацию потока с
          </BCheckbox>
        )}
        {this.state === 'new' && (
          <BFormGroup
            state={this.customStreamNumber.hasError ? false : null}
            invalid-feedback={this.customStreamNumber.errorText}
          >
            <BInput
              value={this.customStreamNumber.value}
              onInput={this.customStreamNumber.set}
              placeholder="Введите номер потока"
              state={this.customStreamNumber.hasError ? false : null}
              disabled={!this.useCustomStreamNumber}
              number
              type="number"
            />
          </BFormGroup>
        )} */}

        {this.courseType === 'free' && (
          <BFormGroup
            label="ID промокода"
            state={this.promocodeId.hasError ? false : null}
            invalid-feedback={this.promocodeId.errorText}
          >
            <b-input-group>
              <BInput
                value={this.promocodeId.value}
                onInput={this.promocodeId.set}
                placeholder="Введите id промокода"
                state={this.promocodeId.hasError ? false : null}
                number
                type="number"
              />
              <b-input-group-append>
                <BButton
                  onClick={() => this.promocodeSelectModal.show()}
                  variant="outline-primary"
                >
                  Выбрать
                </BButton>
              </b-input-group-append>
            </b-input-group>
          </BFormGroup>
        )}

        {this.courseType === 'free' && (
          <BFormGroup
            label="Telegram чат потока"
            state={this.telegramChat.hasError ? false : null}
            invalid-feedback={this.telegramChat.errorText}
          >
            <BInput
              value={this.telegramChat.value}
              onInput={this.telegramChat.set}
              placeholder="Введите ссылку"
              state={this.telegramChat.hasError ? false : null}
              v-mask={this.tgMask}
            />
          </BFormGroup>
        )}

        {this.courseType === 'free' && (
          <BFormGroup
            label="Telegram бот"
            state={this.telegramBot.hasError ? false : null}
            invalid-feedback={this.telegramBot.errorText}
          >
            <BInput
              value={this.telegramBot.value}
              onInput={this.telegramBot.set}
              placeholder="Введите ссылку"
              state={this.telegramBot.hasError ? false : null}
              v-mask={this.tgMask}
            />
          </BFormGroup>
        )}

        {this.courseType === 'paid' && (
          <BFormGroup
            label="Telegram канал уведомлений"
            state={this.telegramNotifications.hasError ? false : null}
            invalid-feedback={this.telegramNotifications.errorText}
          >
            <BInput
              value={this.telegramNotifications.value}
              onInput={this.telegramNotifications.set}
              placeholder="Введите ссылку"
              state={this.telegramNotifications.hasError ? false : null}
              v-mask={this.tgMask}
            />
          </BFormGroup>
        )}

        {this.courseType === 'paid' && (
          <div>
            <p>Telegram каналы менторов:</p>
            {this.mentors.map(mentor => (
              <div class="d-flex align-items-center mb-25">
                <BAvatar src={mentor.avatar} text={mentor.name} class="mr-50" />
                <span class="mr-50" style="min-width: 120px;">
                  {mentor.name}
                </span>

                <BInput
                  value={mentor.telegram}
                  onInput={value => (mentor.telegram = value)}
                  placeholder="Введите ссылку"
                  v-mask={this.tgMask}
                />
              </div>
            ))}
          </div>
        )}
        <PromocodeSelectModal
          ref="promocodeSelectModal"
          onSelect={promocode => (this.promocodeId.value = promocode.id)}
        />
      </BModal>
    )
  }
}
